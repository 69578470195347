import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxLoadingModule } from 'ngx-loading';
import { ChartsModule } from 'ng2-charts';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainComponent } from './main/main.component';
import { DateRangePickerComponent } from './main/date-range-picker/date-range-picker.component';
import { TimelineViewComponent } from './main/timeline-view/timeline-view.component';
import { CardsViewComponent } from './main/cards-view/cards-view.component';

import { TimeFilterComponent } from './main/time-filter/time-filter.component';

import { DataService } from './shared/data.service';
import { ConnectionService } from './shared/connection.service';
import { MapsViewComponent } from './main/cards-view/maps-view/maps-view.component';
import { PanosViewComponent } from './main/cards-view/panos-view/panos-view.component';
import { TotalsViewComponent } from './main/cards-view/totals-view/totals-view.component';
import { MainTableViewComponent } from './main/main-table-view/main-table-view.component';
import { UserDataViewComponent } from './main/user-data-view/user-data-view.component';
import { LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { ClientsTableComponent } from './main/main-table-view/clients-table/clients-table.component';
import { GeneralTableComponent } from './main/main-table-view/general-table/general-table.component';


registerLocaleData(localeEs);

@NgModule({
  declarations: [
    // ShowSidebarDirective,
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    MainComponent,
    DateRangePickerComponent,
    TimelineViewComponent,
    CardsViewComponent,
    TimeFilterComponent,
    MapsViewComponent,
    PanosViewComponent,
    TotalsViewComponent,
    MainTableViewComponent,
    UserDataViewComponent,
    ClientsTableComponent,
    GeneralTableComponent,
  ],
  imports: [
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxLoadingModule.forRoot({}),
    ChartsModule,
    DataTablesModule,

    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [DataService, ConnectionService,
    { provide: LOCALE_ID, useValue: 'es-ES' }],
  bootstrap: [AppComponent]
})
export class AppModule {


}
