<!-- Sidebar -->
<div id="sidebar-wrapper" class="menuDisplayed">

    <ul class="sidebar-nav">
        <li>
            <div class="sidebar-nav-item">
                <span>GByte Price</span>
                <div id='inputGbytePrice-wrapper'>
                    <i class="fa fa-dollar"></i> <input #inputGbytePrice [(ngModel)]="gbPriceInputValue" type="text" id="inputGbytePrice"
                    class="form-control" required autofocus>
                </div>
            </div>
        </li>
        <li class="dropdown">
            <div id="region-selector">
                <div class='sidebar-nav-item'>
                    Region <span id='regionDisplay' #regionDisplay><b>{{chosenRegion}}</b></span>
                </div>
                    
                <ul class="dropdown_menu dropdown_menu-6 dropdown_menu--animated">
                    <li class="dropdown_item" *ngFor="let region of regions; let i = index"
                        (click)="onSelectRegion($event)">
                        <i class='fa fa-angle-right'></i><span>{{region}}</span>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    <button id='queryButton' class="btn btn-lg" (click)="onSetParams()"> Submit </button>

</div>