import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConnectionService } from './connection.service';
import * as moment from 'moment';
import { QueryData } from './models/queryData.model';
import { ResultData, ResultDataMonthly } from './models/resultData.model';
import { GeneralTableData } from './models/TableData.model';
import { GraphService } from './graph.service';


@Injectable({ providedIn: 'root' })
export class DataService {

    timelineUpdatedResponse: Subject<any> = new Subject<any>();

    totalCardsUpdatedResponse: Subject<any> = new Subject<any>();

    mapCardsUpdatedResponse: Subject<any> = new Subject<any>();

    panosCardsUpdatedResponse: Subject<any> = new Subject<any>();

    mainTableUpdatedResponse: Subject<any> = new Subject<any>();

    userDataGlobalUpdatedResponse: Subject<any> = new Subject<any>();

    updatedDateRangeText: Subject<any> = new Subject<any>();


    showLoader = false;

    emptyResponse = false;

    queryData: QueryData = {
        gbPrice: 0,
        region: '',
        dateRange: '',
        timeFilter: '',
    };

    dateRangeText: string;

    totalsData: {};

    mapsData: {};

    panosData: {};

    constructor(private connectionService: ConnectionService) { }

    /* HELPER METHODS */
    private computePcnt(part: number, total: number): number {
        const pcnt = part * 100 / total;
        return pcnt;
    }

    public roundNumber(num: any, dec: any): any {
        return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
    }

    private computeCostsBasic(scBytes: number): {} {
        // let scGbytes = scBytes * 9.1 * Math.pow(10, -10);
        return scBytes * 9.3 * Math.pow(10, -10) * this.queryData.gbPrice;
    }

    public getRandomColor() {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        const s = '0.6';
        return 'rgba(' + r + ',' + g + ',' + b + ',' + s + ')';
    }

    public merge(x, y, fn): {} {
        const result = {};

        Object.keys(x).forEach(k => {
            result[k] = x[k];
        });

        Object.keys(y).forEach(k => {
            result[k] = k in x ? fn(x[k], y[k]) : y[k];
        });

        return result;
    }



    public addClients(data, domainsclients): GeneralTableData[] {
        // {"ticketmaster":{"name":"ticketmaster","cost":123}}
        // {"ticketmaster":{"name":"ticketmaster","totalcost":123,"venues":[{venue_id:'eu-es-00001',cost:123}]}}
        for (let i = 0; i < data.length; i++) {
            let origin = data[i].origin;
            let client = domainsclients[origin];
            if (!client) {
                for (const aux_dom in domainsclients) {
                    if (origin.includes(aux_dom)) {
                        client = domainsclients[aux_dom];
                    }
                }
            }
            data[i]['client'] = client;
        }
        return data;
    }

    // To preserve .this context we need to set add as arrow function
    // Arrow functions can access Parent scope, in this case: data.service
    public add = (p: {}, q: string | number): number | [number, number] | string | [string, string] | {} => {
        if (typeof p === 'object' && typeof q === 'object') {
            return this.merge(p, q, this.add);
        }
        if (typeof p === 'number' && typeof q === 'number') {
            return p + q;
        }
        if (typeof p === 'string' && typeof q === 'string') {
            if (p === q) {
                return p;
            } else {
                return [p, q];
            }
        }
        else {
            if (!(q in p)) {
                return [p, q];
            } else {
                return p;
            }
        }
    }


    /* PARAMETER SETTER METHODS */
    setDateRange(newRange: string): void {
        // debugger;
        if (newRange === 'Invalid date:Invalid date') {
            console.log('Date is not in a valid format');
        }
        else {
            this.queryData.dateRange = newRange;
        }
    }

    setDateRangeText(rangeText: string): void {
        this.dateRangeText = rangeText;
        this.updatedDateRangeText.next(rangeText);
    }

    setGbPrice(newValue: number): void {
        this.queryData.gbPrice = newValue;
    }

    setRegion(newRegion: string): void {
        this.queryData.region = newRegion;
    }

    setTimeFilter(timeFilter: string): void {
        this.queryData.timeFilter = timeFilter;
    }

    getDateRangeText(): Observable<string> {
        return this.updatedDateRangeText.asObservable();
    }

    /* PARAMETER GETTER METHODS */
    getTimeFilter(): string {
        return this.queryData.timeFilter;
    }

    private extractMonths(startDate: moment.MomentInput, endDate: moment.MomentInput): string[] {
        startDate = moment(startDate).format('YYYY-MM');
        endDate = moment(endDate).format('YYYY-MM');
        return [startDate, endDate];
    }

    private getDates({ startDate, endDate }: { startDate: moment.MomentInput; endDate: moment.MomentInput; },
        timeFilter: string): string[] {
        const dateArray = [];
        let unit: moment.unitOfTime.DurationConstructor = 'days';
        let format = 'YYYY-MM-DD';
        if (timeFilter === 'monthly') {
            unit = 'months';
            format = 'YYYY-MM';
        }
        let currentDate = moment(startDate).format(format);
        const stopDate = moment(endDate).format(format);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format(format));
            currentDate = moment(currentDate).add(1, unit).format(format);
        }
        return dateArray;
    }

    /* PROCESSING METHODS */
    private timelineProcess(data: any): any {

        let dates: any;
        const gatheredData = {};
        const plainData = {};
        let hits = 0;
        let totalbytes = 0;
        let property = '';
        // debugger;
        if (this.queryData.timeFilter === 'monthly') {
            property = 'month';
            dates = this.extractMonths(this.queryData.dateRange.split(':')[0], this.queryData.dateRange.split(':')[1]);
            dates = this.getDates({ startDate: dates[0], endDate: dates[1] }, 'monthly');
        } else {
            property = 'date';
            dates = this.getDates({ startDate: this.queryData.dateRange.split(':')[0], endDate: this.queryData.dateRange.split(':')[1] }, 'daily');
        }
        dates.forEach(date => {
            gatheredData[date] = 0;
            plainData[date] = 0;
            data.forEach((el: { property: string; sc_bytes: any; hits: number }) => {
                if (el[property] === date) {
                    gatheredData[date] += el.sc_bytes;
                    plainData[date] += el.sc_bytes;
                    totalbytes += el.sc_bytes;
                    hits += el.hits;
                }
            });

            plainData[date] = plainData[date];
            gatheredData[date] = this.computeCostsBasic(gatheredData[date]);
        });

        // console.log(plainData, hits, totalbytes);
        return gatheredData;
    }

    userDataViewProcess(data: any): any[] {
        let gatheredData = {};
        const userAgentValues = [];
        const userAgentKeys = ['PC', 'Mobile', 'Tablet'];
        data.forEach((el: ResultData | ResultDataMonthly, i: number) => {
            gatheredData = this.merge(gatheredData, el.user_agent, this.add);
        });
        userAgentKeys.forEach((el, i) => {
            if (!(el in gatheredData)) {
                gatheredData[el] = {};
                gatheredData[el].quantity = 0;
            }
        });

        if ('PCBot' in gatheredData) {
            userAgentValues[0] = gatheredData['PC']['quantity'] + gatheredData['PCBot']['quantity'];
        } else {
            userAgentValues[0] = gatheredData['PC']['quantity'];
        }
        if ('MobileBot' in gatheredData) {
            userAgentValues[1] = gatheredData['Mobile']['quantity'] + gatheredData['MobileBot']['quantity'];
        } else {
            userAgentValues[1] = gatheredData['Mobile']['quantity'];
        }
        if ('TabletBot' in gatheredData) {
            userAgentValues[2] = gatheredData['Tablet']['quantity'] + gatheredData['TabletBot']['quantity'];
        } else {
            userAgentValues[2] = gatheredData['Tablet']['quantity'];
        }
        return [userAgentKeys, userAgentValues];
    }

    private cardsViewProcess(data: Array<any>): any {
        const decNum = 3;
        // Totals
        let totalLoads = 0;
        let totalCost = 0;

        // Maps
        let maploads = 0;
        let costMapsTotal = 0;
        let pcntTk3d = 0;
        let pcntD2m = 0;

        let costMfull = 0;
        let costTiles = 0;
        let costSvg = 0;
        let costJson = 0;
        let costImgs = 0;
        let costOthers = 0;

        // Panos
        let panosloads = 0;
        let costPanosTotal = 0;
        let pcntPanosOld = 0;
        let pcntPanosNew = 0;
        let costPanosOldTotal = 0;
        let costPanosNewTotal = 0;

        let costPanosOldImgs = 0;
        let costPanosOldJson = 0;
        let costPanosOldOtherImgs = 0;
        let costPanosOldOtherRes = 0;
        let hitsPanosOldImgs = 0;
        let hitsPanosOldJson = 0;
        let hitsPanosOldOtherImgs = 0;
        let hitsPanosOldOtherRes = 0;

        let costPanosNewConfigJson = 0;
        let costPanosNewImgs = 0;
        let costPanosNewOtherJson = 0;
        let costPanosNewOtherImgs = 0;
        let costPanosNewOtherRes = 0;
        let hitsPanosNewConfigJson = 0;
        let hitsPanosNewImgs = 0;
        let hitsPanosNewOtherJson = 0;
        let hitsPanosNewOtherImgs = 0;
        let hitsPanosNewOtherRes = 0;

        data.forEach((el, index) => {
            totalLoads += el.loads;
            totalCost += el.sc_bytes;

            if (el.lib_type.substring(0, 4) === 'maps') {
                maploads += el.loads;
                costMapsTotal += el.sc_bytes;
                costMfull += el.sc_bytes_masterfull;
                costTiles += el.sc_bytes_tiles;
                costSvg += el.sc_bytes_svg;
                costJson += el.sc_bytes_json;
                costImgs += el.sc_bytes_imgs;
                costOthers += el.sc_bytes_others;
                if (el.lib_type === 'maps_tk3d') {
                    pcntTk3d += el.loads;
                }
                if (el.lib_type === 'maps_d2m') {
                    pcntD2m += el.loads;
                }
            }
            if (el.lib_type.substring(0, 5) === 'panos') {
                panosloads += el.loads;
                costPanosTotal += el.sc_bytes;

                if (el.lib_type === 'panos_old') {
                    pcntPanosOld += el.loads;
                    costPanosOldTotal += el.sc_bytes;

                    costPanosOldJson += el.sc_bytes_panos_old_json;
                    costPanosOldImgs += el.sc_bytes_panos_old_imgs;
                    costPanosOldOtherImgs += el.sc_bytes_panos_old_other_imgs;
                    costPanosOldOtherRes += el.sc_bytes_panos_old_other_res;

                    hitsPanosOldJson += el.sc_bytes_panos_old_json;
                    hitsPanosOldImgs += el.sc_bytes_panos_old_imgs;
                    hitsPanosOldOtherImgs += el.sc_bytes_panos_old_other_imgs;
                    hitsPanosOldOtherRes += el.sc_bytes_panos_old_other_res;
                }
                if (el.lib_type === 'panos_new') {
                    pcntPanosNew += el.loads;
                    costPanosNewTotal += el.sc_bytes;

                    costPanosNewConfigJson += el.sc_bytes_panos_new_config_json;
                    costPanosNewImgs += el.sc_bytes_panos_new_imgs;
                    costPanosNewOtherJson += el.sc_bytes_panos_new_other_json;
                    costPanosNewOtherImgs += el.sc_bytes_panos_new_other_imgs;
                    costPanosNewOtherRes += el.sc_bytes_panos_new_other_res;

                    hitsPanosNewConfigJson += el.hits_panos_new_config_json;
                    hitsPanosNewImgs += el.hits_panos_new_imgs;
                    hitsPanosNewOtherJson += el.hits_panos_new_other_json;
                    hitsPanosNewOtherImgs += el.hits_panos_new_other_imgs;
                    hitsPanosNewOtherRes += el.hits_panos_new_other_res;
                }
            }
        });
        const gatheredMapData = {
            mapLoads: maploads,
            costTotal: this.roundNumber(this.computeCostsBasic(costMapsTotal), decNum),
            costMfull: this.roundNumber(this.computeCostsBasic(costMfull), decNum),
            costTiles: this.roundNumber(this.computeCostsBasic(costTiles), decNum),
            costSvg: this.roundNumber(this.computeCostsBasic(costSvg), decNum),
            costJson: this.roundNumber(this.computeCostsBasic(costJson), decNum),
            costImgs: this.roundNumber(this.computeCostsBasic(costImgs), decNum),
            costOthers: this.roundNumber(this.computeCostsBasic(costOthers), decNum),
            pcntTk3d: this.roundNumber(this.computePcnt(pcntTk3d, maploads), decNum),
            pcntD2m: this.roundNumber(this.computePcnt(pcntD2m, maploads), decNum),
        };
        const gatheredPanosData = {
            panosloads,
            costPanosTotal: this.roundNumber(this.computeCostsBasic(costPanosTotal), decNum),
            costPanosOldTotal: this.roundNumber(this.computeCostsBasic(costPanosOldTotal), decNum),
            costPanosNewTotal: this.roundNumber(this.computeCostsBasic(costPanosNewTotal), decNum),
            pcntOld: this.roundNumber(this.computePcnt(pcntPanosOld, panosloads), decNum),
            pcntNew: this.roundNumber(this.computePcnt(pcntPanosNew, panosloads), decNum),
        };
        const gatheredTotalData = {
            totalLoads,
            totalCost: this.roundNumber(this.computeCostsBasic(totalCost), decNum),
            costPanos: gatheredPanosData.costPanosTotal,
            costMaps: gatheredMapData.costTotal,
            pcntMapsLoads: this.roundNumber(this.computePcnt(gatheredMapData.mapLoads, totalLoads), decNum),
            pcntPanosLoads: this.roundNumber(this.computePcnt(gatheredPanosData.panosloads, totalLoads), decNum),
        };
        this.totalsData = gatheredTotalData;
        this.mapsData = gatheredMapData;
        this.panosData = gatheredPanosData;

        return [gatheredTotalData, gatheredMapData, gatheredPanosData];
    }

    private mainTableProcess(data: ResultData[] | ResultDataMonthly[]): any {
        const gatheredDataGeneral = {};
        const gatheredDataClients = {};
        data.forEach((el, index) => {
            const key = el.origin + '#' + el.venue_code;
            if (key in gatheredDataGeneral) {
                gatheredDataGeneral[key].loads += el.loads;
                gatheredDataGeneral[key].hits += el.hits;
                gatheredDataGeneral[key].sc_Gbytes += el.sc_bytes;
            }
            else {
                gatheredDataGeneral[key] = {
                    origin: el.origin, venue_id: el.venue_code, loads: el.loads,
                    hits: el.hits, cost: 0, sc_Gbytes: el.sc_bytes,
                    user_agent: el.user_agent, viewer_origin: el.viewer_origin
                };
            }
        });
        Object.keys(gatheredDataGeneral).forEach((key) => {
            gatheredDataGeneral[key]['cost'] = this.roundNumber(this.computeCostsBasic(gatheredDataGeneral[key]['sc_Gbytes']), 4);
            gatheredDataGeneral[key]['sc_Gbytes'] = gatheredDataGeneral[key]['sc_Gbytes'] / 9.3 * Math.pow(10, -10);
        });
        // Object.keys(gatheredDataGeneral).forEach((key, index, array) => {
        //     debugger;
        //     let clientKey = key.split("#")[0];
        //     console.log(clientKey);
        // });
        return gatheredDataGeneral;
    }

    /* QUERY METHOD */
    onQueryApi(getData: { date: string, timeFilter: string, region: string }): Subscription {
        if (getData.timeFilter === 'monthly') {
            const months = this.extractMonths(getData.date.split(':')[0], getData.date.split(':')[1]);
            getData.date = months[0] + ':' + months[1];
        }
        const request = this.connectionService.queryApi(getData).subscribe(
            (result: ResultData[] | ResultDataMonthly[]) => {
                request.unsubscribe();
                if (result.length === 0) {
                    this.emptyResponse = true;
                }
                this.userDataGlobalUpdatedResponse.next(this.userDataViewProcess(result));
                this.mainTableUpdatedResponse.next(this.mainTableProcess(result));
                this.timelineUpdatedResponse.next(this.timelineProcess(result));
                this.mapCardsUpdatedResponse.next(this.cardsViewProcess(result)[1]);
                this.panosCardsUpdatedResponse.next(this.cardsViewProcess(result)[2]);
                this.totalCardsUpdatedResponse.next(this.cardsViewProcess(result)[0]);
            }, (error: { message: string }) => {
                console.error(error.message);
            }
        );
        return request;
    }

}