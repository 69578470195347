<div id="cards" class="row justify-content-center"  *ngIf="dispTotalCost != 0">

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Total Cost</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispTotalCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Maps Cost</h3>
                <div class="row">
                    <div class="col text-left">
                        <b><i class="fa fa-dollar"></i> {{dispMapsCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Panos Cost</h3>
                <div class="row">
                    <div class="col text-left">
                        <b><i class="fa fa-dollar"></i> {{dispPanosCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Total Loads</h3>
                <div class="row">
                    <div class="col text-left">
                        <b><i class="fa fa-download"></i> {{dispTotalLoads | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body ">
                <h3>MapLoads</h3>
                <div class="row">
                    <div class="col text-left">
                        <b><i class="fa fa-percent"></i> {{dispPcntMaps | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>PanoLoads</h3>
                <div class="row">
                    <div class="col text-left">
                        <b><i class="fa fa-percent"></i>{{dispPcntPanos | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>