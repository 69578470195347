import { Component, OnInit } from '@angular/core';
import { D3vfactoryService } from 'src/app/shared/3ddvfactory.service';
import { DataService } from 'src/app/shared/data.service';


@Component({
  selector: 'app-main-table-view',
  templateUrl: './main-table-view.component.html',
  styleUrls: ['./main-table-view.component.scss']
})

export class MainTableViewComponent implements OnInit {

  constructor(private d3vfactory: D3vfactoryService, public dataService: DataService) { }

  componentLoader: 'general' | 'clients';

  domainsclients: any;

  updateComponentLoader(value: 'general' | 'clients') {
    if (value) {
      this.componentLoader = value;
    }
  }

  getDomainsClients() {
    this.d3vfactory.getDomainsClients().then(res => {
      this.domainsclients = res.items;
    });
    // console.log(this.domainsclients);
  }

  ngOnInit(): void {
    this.componentLoader = 'general';
    this.getDomainsClients();
  }


}