import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectionService {

    url = 'http://localhost:3000/';
    constructor(private http: HttpClient) { }

    queryApi(data: { date: string, timeFilter: string, region: string }): Observable<any> {
        /* API V1 */
        // Set Query Params
        // let params = new HttpParams();
        // params = params.append('date', data.date);
        // params = params.append('timeFilter', data.timeFilter);
        // params = params.append('region', data.region);

        const endpoint = this.url + 'fetchbydate' + '/' + data.date + '/' + data.timeFilter + '/' + data.region;
        return this.http.get(
            endpoint
        );
    }

    getDomainsClients() {
        let endpoint = this.url + '3ddvfactory/parse/domainsclients';
        return this.http.get(endpoint);
    }

}