<h2>Costs Break Down</h2>
<div class="card p-4">
    <div class="card-header card-popup-effect text-center" [hidden]="dataService.emptyResponse">

        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link map-info-title" data-toggle="tab" (click)="updateComponentLoader('general')"
                    [ngClass]="{'active': componentLoader === 'general'}">General</a>
            </li>
            <li class="nav-item">
                <a class="nav-link map-info-title" data-toggle="tab" (click)="updateComponentLoader('clients')"
                    [ngClass]="{'active': componentLoader === 'clients'}">Clients</a>
            </li>
        </ul>
        <div class="card-body">
            <app-general-table *ngIf='domainsclients' [domainsclients]="domainsclients"
                [hidden]="dataService.emptyResponse || !componentLoader || componentLoader != 'general'">
            </app-general-table>
            <app-clients-table *ngIf='domainsclients' [domainsclients]="domainsclients"
                [hidden]="dataService.emptyResponse || !componentLoader || componentLoader != 'clients'">
            </app-clients-table>
        </div>

    </div>
    <div [hidden]="!dataService.showLoader">
        <ngx-loading [show]=true [config]="{backdropBorderRadius: '5px'}">
        </ngx-loading>
    </div>
    <div class="no-data-alert alert alert-danger" [hidden]="!dataService.emptyResponse">No data</div>
</div>