import { Component, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from './sidebar/sidebar.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'maploads';

  @ViewChild(MainComponent) MainComponent;
  @ViewChild(SidebarComponent) SidebarComponent;

  constructor(private resolver: ComponentFactoryResolver) {
    console.log("hola");
  }

  onResize(sidebarToggled: boolean) {

    this.MainComponent.pageResizeToggled = sidebarToggled;
    this.SidebarComponent.sidebarResizeToggled = sidebarToggled;

  }

}
