import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss']
})
export class TimeFilterComponent implements OnInit {

  constructor(private dataService: DataService) { }

  private timeFilter: string;

  dateRangeText: string;

  ngOnInit(): void {
    this.timeFilter = 'monthly';
    this.dataService.setTimeFilter(this.timeFilter);
  }

  onSetTimeFilter(event: { target: { value: string; }; }): void {
    this.timeFilter = event.target.value;
    this.dataService.setTimeFilter(this.timeFilter);

    if (this.timeFilter === 'monthly') {
      this.dataService.setDateRange(moment(this.dataService.queryData.dateRange.split(':')[0]).format('YYYY-MM') + ':' +
        moment(this.dataService.queryData.dateRange.split(':')[1]).format('YYYY-MM'));
      this.dataService.setDateRangeText(moment(this.dataService.queryData.dateRange.split(':')[0]).format('MMM YYYY') + ' - ' +
        moment(this.dataService.queryData.dateRange.split(':')[1]).format('MMM YYYY'));

    } else {
      this.dataService.setDateRange(moment(this.dataService.queryData.dateRange.split(':')[0]).startOf('month').format('YYYY-MM-DD') + ':' +
        moment(this.dataService.queryData.dateRange.split(':')[1]).endOf('month').format('YYYY-MM-DD'));
      this.dataService.setDateRangeText(moment(this.dataService.queryData.dateRange.split(':')[0]).format('MMMM DD, YYYY')
        + ' - ' + moment(this.dataService.queryData.dateRange.split(':')[1]).format('MMMM DD, YYYY'));
    }
  }

}
