import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  sidebarToggled = false;
  @Output() onToggledButton = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onToggleSidebar() {
    this.sidebarToggled = !this.sidebarToggled;
    this.onToggledButton.emit(this.sidebarToggled);
  }

}
