<div id="datatableContainer" class="container table-responsive nowrap" [hidden]="resultDataClients.length == 0">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead>
            <tr>
                <th>Client</th>
                <th>Origin</th>
                <th>Venue ID</th>
                <th>Loads</th>
                <th>Hits</th>
                <th>Cost</th>
                <th>Gb Transfered</th>
                <th>User agent</th>
                <th>Viewer origin</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entry of resultDataClients" class="row-border">
                <td>{{ entry.client }}</td>
                <td>
                    <div class=cellScroll>
                        <ul style="list-style-type: none;" *ngFor="let origins of entry.origin">
                            <li>{{origins}}</li>
                        </ul>
                    </div>
                </td>
                <td>
                    <div class=cellScroll>
                        <ul style="list-style-type: none;" *ngFor="let venues of entry.venue_id">
                            <li>{{venues}}</li>
                        </ul>
                    </div>
                </td>
                <td>{{ entry.loads }}</td>
                <td>{{ entry.hits }}</td>
                <td>{{ entry.cost }}</td>
                <td>{{ entry.sc_Gbytes }}</td>
                <td>{{ entry.user_agent }}</td>
                <td>{{ entry.viewer_origin }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Hidden Modals -->
<div id="user-agent-clients" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h3 id="modalTitle">User Agent Data</h3>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="no-data-alert alert alert-danger" *ngIf="this.dataSet[0].data.length == 0">No Info</div>
                <canvas baseChart id="usrDataChart" *ngIf="this.dataSet[0].data.length != 0"
                    [hidden]="this.dataSet[0].data.length == 0" [legend]="this.legend" [chartType]="this.chartType"
                    [datasets]="this.dataSet" [labels]="this.chartLabels" [options]="this.chartOptions"></canvas>
                <hr>
                <canvas baseChart id="userAgentOs" *ngIf="this.dataSet2[0].data.length != 0"
                    [hidden]="this.dataSet2[0].data.length == 0" [legend]="this.legend" [chartType]="this.chartType"
                    [datasets]="this.dataSet2" [labels]="this.chartLabels2" [options]="this.chartOptions"></canvas>
                <div class="no-data-alert alert alert-danger" *ngIf="this.dataSet2[0].data.length == 0">No Info</div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>
            </div>
        </div>
    </div>
</div>

<div id="viewer-origin-clients" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h3 id="modalTitle">Viewer Continents and Countries</h3>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">

                <div class="no-data-alert alert alert-danger" *ngIf="this.dataSet[0].data.length == 0">No Info</div>
                <canvas baseChart #viewerContinent id="viewerContinent" *ngIf="this.dataSet[0].data.length != 0"
                    [hidden]="this.dataSet[0].data.length == 0" [legend]="this.legend" [chartType]="this.chartType"
                    [datasets]="this.dataSet" [labels]="this.chartLabels" [options]="this.chartOptions"></canvas>
                <hr>
                <canvas baseChart id="viewerCountry" *ngIf="this.dataSet2[0].data.length != 0"
                    [hidden]="this.dataSet2[0].data.length == 0" [legend]="this.legend" [chartType]="this.chartType"
                    [datasets]="this.dataSet2" [labels]="this.chartLabels2" [options]="this.chartOptions"></canvas>
                <div class="no-data-alert alert alert-danger" *ngIf="this.dataSet2[0].data.length == 0">No Info</div>
            </div>

            <div class="modal-footer">
                <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>
            </div>
        </div>
    </div>
</div>