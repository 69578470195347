<!-- Cards Info Totals Info-->

<h2>Resources Cost</h2>
<div class="card" id=wrapper-card>
    <div [hidden]="!dataService.showLoader">
        <ngx-loading [show]=true [config]="{backdropBorderRadius: '5px'}">
        </ngx-loading>
    </div>
    <div class="no-data-alert alert alert-danger" [hidden]="!dataService.emptyResponse">No data</div>
    <div class="card-header card-popup-effect text-center" [hidden]="dataService.emptyResponse">

        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link map-info-title" data-toggle="tab" (click)="updateComponentLoader('totals')"
                    [ngClass]="{'active': componentLoader === 'totals'}">Totals</a>
            </li>
            <li class="nav-item">
                <a class="nav-link map-info-title" data-toggle="tab" (click)="updateComponentLoader('maps')"
                    [ngClass]="{'active': componentLoader === 'maps'}">Maps</a>
            </li>
            <li class="nav-item">
                <a class="nav-link map-info-title" data-toggle="tab" (click)="updateComponentLoader('panos')"
                    [ngClass]="{'active': componentLoader === 'panos'}">Panos</a>
            </li>
        </ul>
        <div class="card-body">
            <app-totals-view [hidden]="dataService.emptyResponse || !componentLoader || componentLoader != 'totals'">
            </app-totals-view>
            <app-maps-view [hidden]="dataService.emptyResponse || !componentLoader || componentLoader != 'maps'">
            </app-maps-view>
            <app-panos-view [hidden]="dataService.emptyResponse || !componentLoader || componentLoader != 'panos'">
            </app-panos-view>
        </div>

    </div>
</div>