import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-panos-view',
  templateUrl: './panos-view.component.html',
  styleUrls: ['./panos-view.component.scss']
})
export class PanosViewComponent implements OnInit {

  dataSubscription: Subscription;

  dispTotalCost = 0;
  dispPanosOldCost: number;
  dispPanosNewCost: number;
  dispPanosloads: number;
  dispPcntOld: number;
  dispPcntNew: number;

  dispCostMfull: number;
  dispCostTiles: number;
  dispCostSvg: number;
  dispCostJson: number;
  dispCostImgs: number;
  dispCostOthers: number;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {

    this.dataSubscription = this.dataService.panosCardsUpdatedResponse
      .subscribe({
        next: (data: any) => {
          this.dispTotalCost = data.costPanosTotal;
          this.dispPanosOldCost = data.costPanosOldTotal;
          this.dispPanosNewCost = data.costPanosNewTotal;
          this.dispPanosloads = data.panosloads;
          this.dispPcntOld = data.pcntOld;
          this.dispPcntNew = data.pcntNew;
        }
      });

  }

}
