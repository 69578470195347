import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GeneralTableData, ClientsTableData } from '../../../shared/models/TableData.model';
import { DataTableDirective } from 'angular-datatables';
import { DataService } from 'src/app/shared/data.service';

import * as bootstrap from 'bootstrap';
import { GraphService } from 'src/app/shared/graph.service';
import { D3vfactoryService } from 'src/app/shared/3ddvfactory.service';
import { ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss']
})
export class ClientsTableComponent implements OnInit {

  constructor(private d3vfactory: D3vfactoryService, public dataService: DataService, public graphService: GraphService) { }

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @Input() domainsclients: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  tableData: Subscription;
  resultData: GeneralTableData[] = [];
  resultDataClients: ClientsTableData[] = [];
  isDtInitialized = false;
  usrData = [];
  viewerOrigin = [];

  // Chart Parameters

  chartType: ChartType;
  legend: boolean;
  chartOptions: Chart.ChartOptions;
  public chartLabels: Label[];
  public chartLabels2: Label[];
  public dataSet: { data: SingleDataSet }[] = [
    {
      data: Array<any>()
    }
  ];

  public dataSet2: { data: SingleDataSet }[] = [
    {
      data: Array<any>()
    }
  ];

  private displayUserDataClients(data: { [x: string]: { [x: string]: any; }; }) {
    debugger;
    this.dataSet = [{ data: Array<any>() }];
    this.dataSet2 = [{ data: Array<any>() }];
    const keys = Object.keys(data);
    const values = [];
    const keys2 = [];
    const osKeys = [];
    const osValues = [];

    if (keys.length) {
      keys.forEach((el, i) => {
        values[i] = data[el].quantity;
        keys2[i] = data[el]['operating systems'];
      });
      keys2.forEach((el, i) => {
        Object.keys(el).forEach((item, index) => {
          if (osKeys.includes(item)) {
            osValues[osKeys.indexOf(item)] += el[item].quantity;
          } else {
            osKeys.push(item);
            osValues.push(el[item].quantity);
          }
        });
      });
      this.chartType = this.graphService.chartType;
      this.legend = this.graphService.legend;
      this.chartOptions = this.graphService.chartOptions;

      this.chartLabels = this.graphService.getLabels(values, keys);
      this.dataSet[0].data = values;

      this.chartLabels2 = this.graphService.getLabels(osValues, osKeys);
      this.dataSet2[0].data = osValues;
    }
  }

  private displayViewerOriginClients(data: { [x: string]: { [x: string]: any; }; }) {
    const keys = Object.keys(data);
    this.dataSet = [{ data: Array<any>() }];
    this.dataSet2 = [{ data: Array<any>() }];

    const values = [];
    const keys2 = [];
    const osKeys = [];
    const osValues = [];
    if (keys.length) {
      keys.forEach((el, i) => {
        values[i] = data[el].quantity;
        keys2[i] = Object.assign({}, data[el]);
        delete keys2[i].quantity;
      });

      keys2.forEach((el) => {
        Object.keys(el).forEach((item) => {
          if (osKeys.includes(item)) {
            osValues[osKeys.indexOf(item)] += el[item].quantity;
          } else {
            osKeys.push(item);
            osValues.push(el[item].quantity);
          }
        });
      });

      this.chartType = this.graphService.chartType;
      this.legend = this.graphService.legend;
      this.chartOptions = this.graphService.chartOptions;

      this.chartLabels = this.graphService.getLabels(values, keys);
      this.dataSet[0].data = values;

      this.chartLabels2 = this.graphService.getLabels(osValues, osKeys);
      this.dataSet2[0].data = osValues;

    }

  }

  ngOnInit(): void {
    this.resultDataClients = [];
    this.dtOptions = {
      paging: true,
      pagingType: 'full_numbers',
      responsive: true,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', exportOptions: { columns: ':visible' } },
        {
          extend: 'csv', exportOptions: { columns: ':visible' },
          messageTop: () => {
            return 'Date Range:  ' + this.dataService.dateRangeText + '    Total Cost  ' + this.dataService.totalsData['totalCost'] + ' $' + '    ' +
              'Region:  ' + this.dataService.queryData.region + '    Panos Cost:  ' + this.dataService.panosData['costPanosTotal'] + ' $' + '    ' +
              'GB price  ' + this.dataService.queryData.gbPrice + ' $' + '    Maps Cost:  ' + this.dataService.mapsData['costTotal'] + ' $' + '  ';
          }
        },
        {
          extend: 'excel', exportOptions: { columns: ':visible' },
          messageTop: () => {
            return 'Date Range:  ' + this.dataService.dateRangeText + '    Total Cost  ' + this.dataService.totalsData['totalCost'] + ' $' + '    ' +
              'Region:  ' + this.dataService.queryData.region + '    Panos Cost:  ' + this.dataService.panosData['costPanosTotal'] + ' $' + '    ' +
              'GB price  ' + this.dataService.queryData.gbPrice + ' $' + '    Maps Cost:  ' + this.dataService.mapsData['costTotal'] + ' $' + '  ';
          }
        },
        {
          extend: 'print', exportOptions: { columns: ':visible' },
          messageTop: () => {
            return '<table>' +
              '<tr><th>Date Range:&ensp;</th><td>' + this.dataService.dateRangeText + '</td><th>&emsp;Total Cost:&ensp;</th><td>' + this.dataService.totalsData['totalCost'] + ' $' + '</td></tr>' +
              '<tr><th>Region:&ensp;</th><td>' + this.dataService.queryData.region + '</td><th>&emsp;Panos Cost:&ensp;</th><td>' + this.dataService.panosData['costPanosTotal'] + ' $' + '</td></tr>' +
              '<tr><th>GB price:&ensp;</th><td>' + this.dataService.queryData.gbPrice + ' $' + '</td><th>&emsp;Maps Cost:&ensp;</th><td>' + this.dataService.mapsData['costTotal'] + ' $' + '</td></tr>' +
              '</table>';
          }
        },
        { extend: 'colvis', collectionLayout: 'fixed three-column' }
      ],
      destroy: true,
      columns: [
        { data: 'client' },
        { data: 'origin' },
        { data: 'venue_id' },
        { data: 'loads' },
        { data: 'hits' },
        { data: 'cost' },
        { data: 'sc_Gbytes' },
        {
          data: 'user_agent',
          render: (data: any, type: any, row: any, meta: any) => {
            return $('<button></button>', {
              text: 'Details',
              type: 'button',
              class: 'btn btn-outline-primary btn-sm getDetailsUserDataClients',
              'data-toggle': 'modal',
              'data-target': '#user-agent-clients',
              'data-id': meta.row,
              id: meta.row,
            }).prop('outerHTML');
          }
        },
        {
          data: 'viewer_origin',
          render: (
            data: any,
            type: any,
            row: { cost: number, hits: number, loads: number }, meta: any) => {

            return $('<button></button>', {
              text: 'Details',
              type: 'button',
              class: 'btn btn-outline-primary btn-sm getDetailsViewerOriginClients',
              'data-toggle': 'modal',
              'data-target': '#viewer-origin-clients',
              id: meta.row,
            }).prop('outerHTML');
          }
        },
      ],
    };


    $(document).on('click', '.getDetailsUserDataClients', (e) => {
      // console.log(e);
      const data = this.usrData[parseInt(e.currentTarget.id)];
      const modal = $('#user-agent-clients');
      modal.modal('show');
      this.displayUserDataClients(data);

    });

    $(document).on('click', '.getDetailsViewerOriginClients', (e) => {
      const data = this.viewerOrigin[parseInt(e.currentTarget.id)];
      const modal = $('#viewer-origin-clients');
      modal.modal('show');
      this.displayViewerOriginClients(data);
    });


    this.tableData = this.dataService.mainTableUpdatedResponse
      .subscribe({
        next: (data: any) => {
          const gatheredDataClients = [];
          const clientsArray = [];

          const result = Object.keys(data).map((key) => data[key]);
          this.resultData = result;
          this.resultData = this.dataService.addClients(this.resultData, this.domainsclients);

          this.resultData.forEach((el, index, array) => {
            const clientIndex = clientsArray.indexOf(el.client);
            if (clientIndex > -1) {
              const currentClient = gatheredDataClients[clientIndex];

              if (currentClient.origin.indexOf(el.origin) <= -1) {
                currentClient.origin.push(el.origin);
              }
              if (currentClient.venue_id.indexOf(el.venue_id) <= -1) {
                currentClient.venue_id.push(el.venue_id);
              }
              let updateOriginList = currentClient.origin;
              let updateVenueList = currentClient.venue_id;

              let updateElement: ClientsTableData = {
                client: currentClient.client,
                cost: currentClient.cost + el.cost,
                hits: currentClient.hits + el.hits,
                loads: currentClient.loads + el.loads,
                sc_Gbytes: currentClient.sc_Gbytes + el.sc_Gbytes,
                origin: updateOriginList,
                venue_id: updateVenueList,
                user_agent: this.dataService.merge(currentClient.user_agent, el.user_agent, this.dataService.add),
                viewer_origin: this.dataService.merge(currentClient.viewer_origin, el.viewer_origin, this.dataService.add)
              };
              gatheredDataClients[clientIndex] = {};
              gatheredDataClients[clientIndex] = updateElement;

            } else {
              clientsArray.push(el.client);
              let newElement: ClientsTableData = {
                client: el.client,
                cost: el.cost,
                hits: el.hits,
                loads: el.loads,
                sc_Gbytes: el.sc_Gbytes,
                origin: [el.origin],
                venue_id: [el.venue_id],
                user_agent: el.user_agent,
                viewer_origin: el.viewer_origin
              };
              gatheredDataClients.push(newElement);
            }
          });
          this.resultDataClients = gatheredDataClients;
          this.resultDataClients.map((val, index) => {
            this.usrData[index] = val.user_agent;
            this.viewerOrigin[index] = val.viewer_origin;
          });
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }
      });
  }

}
