import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-totals-view',
  templateUrl: './totals-view.component.html',
  styleUrls: ['./totals-view.component.scss']
})
export class TotalsViewComponent implements OnInit {

  dataSubscription: Subscription;

  dispTotalCost = 0;
  dispTotalLoads: number;
  dispPanosCost: number;
  dispMapsCost: number;
  dispPcntMaps: number;
  dispPcntPanos: number;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.dataSubscription = this.dataService.totalCardsUpdatedResponse
      .subscribe({
        next: (data: any) => {
          this.dispTotalLoads = data.totalLoads;
          this.dispTotalCost = data.totalCost;
          this.dispPanosCost = data.costPanos;
          this.dispMapsCost = data.costMaps;
          this.dispPcntMaps = data.pcntMapsLoads;
          this.dispPcntPanos = data.pcntPanosLoads;
        }
      })
  }

}
