import { Injectable, ViewChild } from '@angular/core';
import { Chart, ChartOptions, ChartType } from 'chart.js';
import { SingleDataSet, Label, BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class GraphService {

    // Chart Parameters
    @ViewChild(BaseChartDirective) baseChartDir: BaseChartDirective;
    chartType: ChartType = 'doughnut';
    chartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false
        },
        legend: {
            position: 'left',
            onHover: (event) => {
                // event.target.style.cursor = 'pointer';
                // console.log(event);
            }
        }
    };
    public legend = true;
    public chartLabels: Label[];
    public dataSet: { data: SingleDataSet }[] = [
        {
            data: Array<any>()
        }
    ];

    constructor(public dataService: DataService) { }

    chartSubscription: Subscription;


    setChartParams(chartType: ChartType, /*chartOptions: ChartOptions,*/ dataSet: { data: SingleDataSet }[], labels: Label[]) {
        this.chartType = chartType;
        // this.chartOptions = chartOptions;
        this.dataSet = dataSet;
        this.chartLabels = labels;
    }

    public getLabels(data: any, labels: string[]) {
        const newData = [];
        const newLabels = [];
        let minorities = 0;
        const total = data.reduce((accumulator: string, currentValue: string) => parseInt(accumulator) + parseInt(currentValue));
        const labelsvalues = data.map((value: number, i: string | number, arr: any) => {
            // const pcnt = Math.round((value / total) * 100);
            const tmpPcnt = this.dataService.roundNumber((value / total) * 100, 8);
            const pcnt = Math.round(tmpPcnt);
            // debugger;

            if (tmpPcnt < 1) {
                minorities = minorities + tmpPcnt;
                let p = '< 1%';
                return labels[i] + ' ' + p;
            } else {
                return labels[i] + ' ' + pcnt + '%';
            }
        });
        return labelsvalues;
    }

    // public displayUserData(
    //     data: { [x: string]: { [x: string]: any; }; },
    //     dataSet, dataSet2, chartLabels, chartLabels2, chartOptions, chartType, legend) {
    //     dataSet = [{ data: Array<any>() }];
    //     dataSet2 = [{ data: Array<any>() }];
    //     const keys = Object.keys(data);
    //     const values = [];
    //     const keys2 = [];
    //     const osKeys = [];
    //     const osValues = [];

    //     if (keys.length) {
    //         keys.forEach((el, i) => {
    //             values[i] = data[el].quantity;
    //             keys2[i] = data[el]['operating systems'];
    //         });
    //         keys2.forEach((el, i) => {
    //             Object.keys(el).forEach((item, index) => {
    //                 if (osKeys.includes(item)) {
    //                     osValues[osKeys.indexOf(item)] += el[item].quantity;
    //                 } else {
    //                     osKeys.push(item);
    //                     osValues.push(el[item].quantity);
    //                 }
    //             });
    //         });

    //         // return this.chartType, this.legend, this.chartOptions, this.getLabels(values, keys), values, osValues
    //         chartType = this.chartType;
    //         legend = this.legend;
    //         chartOptions = this.chartOptions;

    //         chartLabels = this.getLabels(values, keys);
    //         dataSet[0].data = values;

    //         chartLabels2 = this.getLabels(osValues, osKeys);
    //         dataSet2[0].data = osValues;
    //     }
    // }

    // public displayViewerOrigin(
    //     data: { [x: string]: { [x: string]: any; }; },
    //     dataSet, dataSet2, chartLabels, chartLabels2, chartOptions, chartType, legend) {
    //     const keys = Object.keys(data);
    //     dataSet = [{ data: Array<any>() }];
    //     dataSet2 = [{ data: Array<any>() }];

    //     const values = [];
    //     const keys2 = [];
    //     const osKeys = [];
    //     const osValues = [];
    //     if (keys.length) {
    //         keys.forEach((el, i) => {
    //             values[i] = data[el].quantity;
    //             keys2[i] = Object.assign({}, data[el]);
    //             delete keys2[i].quantity;
    //         });

    //         keys2.forEach((el) => {
    //             Object.keys(el).forEach((item) => {
    //                 if (osKeys.includes(item)) {
    //                     osValues[osKeys.indexOf(item)] += el[item].quantity;
    //                 } else {
    //                     osKeys.push(item);
    //                     osValues.push(el[item].quantity);
    //                 }
    //             });
    //         });

    //         chartType = this.chartType;
    //         legend = this.legend;
    //         chartOptions = this.chartOptions;

    //         // this.myColors = colorLabels;
    //         chartLabels = this.getLabels(values, keys);
    //         dataSet[0].data = values;

    //         // this.myColors2 = colorLabels2;
    //         chartLabels2 = this.getLabels(osValues, osKeys);
    //         dataSet2[0].data = osValues;

    //     }

    // }


}