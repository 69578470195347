<div id="cards" class="row justify-content-center" *ngIf="dispTotalCost != 0">
    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Panos Cost</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispTotalCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>PanosLoads</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-download"></i> {{dispPanosloads | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Old Panos Cost</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispPanosOldCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>New Panos Cost</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispPanosNewCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Old Panos</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-percent"></i> {{dispPcntOld | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>New Panos</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-percent"></i> {{dispPcntNew | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>