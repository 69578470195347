import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/data.service';

@Component({
    selector: 'app-cards-view',
    templateUrl: './cards-view.component.html',
    styleUrls: ['./cards-view.component.scss']
})
export class CardsViewComponent implements OnInit {
    constructor(public dataService: DataService) { }

    componentLoader: 'maps' | 'panos' | 'totals';

    updateComponentLoader(value: 'maps' | 'panos' | 'totals') {
        if (value) {
            this.componentLoader = value;
        }
    }

    ngOnInit(): void {
        this.componentLoader = 'totals';
    }
}
