<div id="cards" class="row" *ngIf="dispTotalCost != 0">
    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Total Cost</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispTotalCost | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>MapLoads</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-download"></i> {{dispMaploads | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>D2M Library</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-percent"></i> {{dispPcntD2m | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='col-6'>
        <div class="card card-info">
            <div class="card-body">
                <h3>TK3D Library</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-percent"></i> {{dispPcntTk3d | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Masterfull</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispCostMfull | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Tiles</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispCostTiles | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>SVG</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispCostSvg | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Static JSON Files</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispCostJson | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Images</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispCostImgs | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-4'>
        <div class="card card-info">
            <div class="card-body">
                <h3>Other files</h3>
                <div class='row'>
                    <div class="col text-left">
                        <b> <i class="fa fa-dollar"></i> {{dispCostOthers | number}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>