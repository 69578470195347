import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-user-data-view',
  templateUrl: './user-data-view.component.html',
  styleUrls: ['./user-data-view.component.scss']
})
export class UserDataViewComponent implements OnInit {

  constructor(public dataService: DataService) { }

  chartSubscription: Subscription;

  public chartLabels: Label[];

  public barChartType: ChartType = 'doughnut';

  label = 'Devices';

  public dataSet: { data: SingleDataSet, label: string }[] = [
    {
      label: this.label,
      data: Array<any>(),

    }
  ];

  public ChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: this.label,
    },
    legend: {
      position: 'bottom',
      onHover: (event) => {
        // event.target.style.cursor = 'pointer';
        // console.log(event);
      }
    }
  };

  private getLabels(data: any, labels: string[]) {
    const total = data.reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue));
    const labelsvalues = data.map((value: number, i: string | number) => {
      const pcnt = Math.round((value / total) * 100);
      if (pcnt === 0) {
        const p = '< 1%';
        return labels[i] + ' ' + p;
      } else {
        return labels[i] + ' ' + pcnt + '%';
      }
    });
    return labelsvalues;
  }

  ngOnInit(): void {
    this.chartSubscription = this.dataService.userDataGlobalUpdatedResponse
      .subscribe({
        next: (data: any[]) => {
          if (data.length === 0) {
            this.dataService.emptyResponse = true;
          }
          this.chartLabels = this.getLabels(data[1], data[0]);
          this.dataSet[0].data = data[1];
        }
      });
  }

}
