import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../shared/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: []
})
export class SidebarComponent implements OnInit {

  sidebarResizeToggled: boolean;

  @ViewChild('regionDisplay') regionDisplay: any;
  gbPriceInputValue: number;
  chosenRegion = 'eu-central-1';
  regions: string[] = [
    'eu-west-1',
    'eu-central-1',
    'us-east-1',
    'us-west-1',
    'ap-northeast-2',
    'All Regions',
  ];

  constructor(private dataService: DataService) { }

  onSelectRegion(event): void {
    this.chosenRegion = event.target.innerText;
    this.dataService.setRegion(this.chosenRegion);
  }

  onSetParams(): void {
    // debugger;
    this.dataService.showLoader = true;
    this.dataService.emptyResponse = false;
    this.dataService.setGbPrice(this.gbPriceInputValue);
    this.dataService.onQueryApi({
      date: this.dataService.queryData.dateRange,
      timeFilter: this.dataService.queryData.timeFilter,
      region: this.dataService.queryData.region
    }).add(
      () => {
        this.dataService.showLoader = false;
      }
    );
  }

  ngOnInit(): void {
    this.dataService.showLoader = true;
    this.dataService.emptyResponse = false;
    this.dataService.setRegion(this.chosenRegion);
    this.dataService.setGbPrice(0.085);
    this.gbPriceInputValue = 0.085;
    console.log(moment().subtract(1, 'days').startOf('month').format("MM-DD-YYYY"));
    this.dataService.setDateRange(moment().subtract(1, 'days').startOf('month').format("MM-DD-YYYY") + ':' + moment().subtract(1, 'days').endOf('month').format("MM-DD-YYYY"));
    this.dataService.setTimeFilter('monthly');
    this.dataService.setGbPrice(this.gbPriceInputValue);
    this.dataService.onQueryApi({
      date: this.dataService.queryData.dateRange, //moment().startOf('month').format("MM-DD-YYYY") + ':' + moment().endOf('month').format("MM-DD-YYYY"),
      timeFilter: 'monthly',
      region: 'eu-central-1'
    }).add(
      () => {
        this.dataService.showLoader = false;
      }
    );

  }



}
