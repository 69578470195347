<div id="page-content-wrapper" class='menuDisplayed'>
    <div role='main' class="container-fluid pt-3 px-4">

        <div class='d-flex justify-content-end flex-wrap align-items-center pb-2 mb-3 border-bottom'>
            <h1 class="h4 subtitle">AWS Cloudfront Cost</h1>
        </div>

        <div class="container">
            <div class="row mt-4 justify-content-center">
                <div class="time-control-panel text-center flex-wrap">
                    <!-- DateTime Picker -->
                    <app-date-range-picker></app-date-range-picker>
                    <!-- Month-Day selector -->
                    <app-time-filter></app-time-filter>
                </div>
            </div>
            <!-- <div *ngIf="this.dataService.queryData.timeFilter == 'monthly'" class="row justify-content-center alert">
                <div class="alert">
                    <span class="closebtn">&times;</span>
                    <strong>Alert: </strong>Using a Custom Range, the range will include full months
                </div>
            </div> -->
        </div>


        <!-- Timeline View -->
        <app-timeline-view></app-timeline-view>

        <div class='container'>
            <div class='row'>
                <div class='col-8'>
                    <!-- Cards View -->
                    <app-cards-view></app-cards-view>
                </div>
                <div class='col-4'>
                    <!-- Global UserData -->
                    <app-user-data-view></app-user-data-view>
                </div>
            </div>
        </div>


        <!-- ------------------------------ -->
        <div class="container-fluid my-4">
            <div class="row">
                <div class="col-12">
                    <!-- Main Table -->
                    <app-main-table-view></app-main-table-view>
                </div>
            </div>
        </div>

    </div>
</div>