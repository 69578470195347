import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';

@Component({
  selector: 'app-maps-view',
  templateUrl: './maps-view.component.html',
  styleUrls: ['./maps-view.component.scss']
})
export class MapsViewComponent implements OnInit {

  constructor(public dataService: DataService) { }

  dataSubscription: Subscription;

  dispTotalCost = 0;
  dispMaploads: number;
  dispPcntTk3d: number;
  dispPcntD2m: number;
  dispCostMfull: number;
  dispCostTiles: number;
  dispCostSvg: number;
  dispCostJson: number;
  dispCostImgs: number;
  dispCostOthers: number;

  ngOnInit(): void {
    this.dataSubscription = this.dataService.mapCardsUpdatedResponse
      .subscribe({
        next: (data: any) => {

          this.dispTotalCost = data.costTotal;

          this.dispMaploads = data.mapLoads;
          this.dispPcntTk3d = data.pcntTk3d;
          this.dispPcntD2m = data.pcntD2m;
          this.dispCostMfull = data.costMfull;
          this.dispCostTiles = data.costTiles;
          this.dispCostSvg = data.costSvg;
          this.dispCostJson = data.costJson;
          this.dispCostImgs = data.costImgs;
          this.dispCostOthers = data.costOthers;
        },
      });
  }

}
