import { Component, OnInit, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data.service';
import { ChartType } from 'node_modules/chart.js/dist/Chart.min.js';
import { SingleDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-timeline-view',
  templateUrl: './timeline-view.component.html',
  styleUrls: ['./timeline-view.component.scss']
})
export class TimelineViewComponent implements OnInit {

  constructor(public dataService: DataService) { }

  chartSubscription: Subscription;

  public loaded = false;

  public legend = true;

  public chartLabels: Label[] = [];

  public barChartType: ChartType = 'bar';

  public timeFilter = 'day';

  public label = 'CDN Cost $';

  public dataSet: { data: SingleDataSet, label: string }[] = [
    {
      label: this.label,
      data: Array<any>()
    }
  ];

  public ChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        display: true,
        ticks: { beginAtZero: true }
        // scaleLabel: {
        //   display: true,
        //   labelString: "$",
        // },
      }],
      xAxes: [
        {
          display: true,
          type: 'time',
          distribution: 'series',
          time: {
            unit: this.timeFilter,
            displayFormats: {
              day: 'MMM DD',
              month: 'MMMM YYYY'
            }
            // },
            // ticks: {
            //   autoSkip: true,
            // }
          }
        }
      ],
    },
    title: {
      display: false,
      text: this.label,
    }
  };



  ngOnInit(): void {
    this.chartSubscription = this.dataService.timelineUpdatedResponse
      .subscribe({
        next: (data: Array<any>) => {
          /* LABELS (dates) */
          // this.chart.chart.config.data.labels = Object.keys(data);
          if (data.length === 0) {
            this.dataService.emptyResponse = true;
          } else {
            // if (this.baseChart.chart) {
            //   this.baseChart.chart.destroy();
            // }
            this.loaded = false;
            this.chartLabels = Object.keys(data);
            this.dataSet[0].data = Object.values(data);
            this.loaded = true;
          }
        }
      });
  }



}
