<div class="time-control-input d-flex align-items-center px-3">
    <i class="fa fa-calendar"></i> &nbsp;

    <input class="form-control dateRangePicker" (datesUpdated)="onSetDateRange($event)" type="text" ngxDaterangepickerMd
        spellcheck="false" [(ngModel)]="selected" [maxDate]="maxDate"
        [locale]="this.dataService.queryData.timeFilter == 'monthly' ? this.monthlyLocale : this.dailyLocale"
        [ranges]="this.dataService.queryData.timeFilter == 'monthly' ? this.monthlyRanges : this.dailyRanges"
        [placeholder]="getPlaceholder()" [showCustomRangeLabel]='true' [isTooltipDate]="isTooltipDate" />

    &nbsp; <i class="fa fa-caret-down"></i>
</div>