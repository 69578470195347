import { ConnectionService } from './connection.service';
import { Injectable } from '@angular/core';
import { rejects } from 'node:assert';

interface apiResponse {
    items: [],
    total: number
}


@Injectable({ providedIn: 'root' })
export class D3vfactoryService {
    constructor(private connectionService: ConnectionService) { }

    protected domainsHashed: apiResponse | PromiseLike<apiResponse>;

    public getDomainsClients(): Promise<apiResponse> {
        // debugger;
        if (!this.domainsHashed) {
            return this.connectionService.getDomainsClients().toPromise().then(res => {
                return res;
            }).catch(err => {
                return err;
            });

        } else {
            return new Promise((resolve, reject) => {
                resolve(this.domainsHashed);
            });
        }
    }

}
