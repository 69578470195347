<h2>Device usage</h2>
<div class="card p-4">
    <canvas baseChart id="timeline-chart" *ngIf="dataSet[0].data.length != 0" [hidden]="dataService.emptyResponse"
        [chartType]="barChartType" [datasets]="dataSet" [labels]="chartLabels"
        [options]="ChartOptions"></canvas>
    <div class=" no-data-alert alert alert-danger" [hidden]="!dataService.emptyResponse">No data</div>
    <div [hidden]="!dataService.showLoader">
        <ngx-loading [show]=true [config]="{backdropBorderRadius: '5px'}">
        </ngx-loading>
    </div>
</div>