<div class="container-fluid my-4">
    <div class="row">
        <div class='col'>
            <h2>Timeline Cost</h2>
            <div class="card card-popup-effect p-4" id="timeline-card">
                <canvas baseChart #baseChart='base-chart' id="timeline-chart"
                    *ngIf="dataSet[0].data.length != 0 && this.loaded" [hidden]="dataService.emptyResponse"
                    [legend]="legend" [chartType]="barChartType" [datasets]="dataSet" [labels]="chartLabels"
                    [options]="this.ChartOptions"></canvas>
                <div class=" no-data-alert alert alert-danger" [hidden]="!dataService.emptyResponse">No data</div>
                <div [hidden]="!dataService.showLoader">
                    <ngx-loading [show]=true [config]="{backdropBorderRadius: '5px'}">
                    </ngx-loading>
                </div>
            </div>
        </div>
    </div>
</div>