import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DataService } from 'src/app/shared/data.service';
import { GeneralTableData } from '../../../shared/models/TableData.model';
import { ChartType } from 'chart.js';
import { SingleDataSet, Label, Color } from 'ng2-charts';
import * as bootstrap from 'bootstrap';
import { GraphService } from 'src/app/shared/graph.service';
// import { D3vfactoryService } from 'src/app/shared/3ddvfactory.service';

@Component({
  selector: 'app-general-table',
  templateUrl: './general-table.component.html',
  styleUrls: ['./general-table.component.scss']
})
export class GeneralTableComponent implements OnDestroy, OnInit {

  constructor(public dataService: DataService, private graphService: GraphService) { }

  // clientCost: any;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @Input() domainsclients: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  tableData: Subscription;
  resultData: GeneralTableData[] = [];
  isDtInitialized = false;
  usrData = [];
  viewerOrigin = [];

  // Chart Parameters

  chartType: ChartType;
  legend: boolean;
  chartOptions: Chart.ChartOptions;
  public chartLabels: Label[];
  public chartLabels2: Label[];
  public dataSet: { data: SingleDataSet }[] = [
    {
      data: Array<any>()
    }
  ];

  public dataSet2: { data: SingleDataSet }[] = [
    {
      data: Array<any>()
    }
  ];

  private displayUserData(data: { [x: string]: { [x: string]: any; }; }) {
    debugger;
    this.dataSet = [{ data: Array<any>() }];
    this.dataSet2 = [{ data: Array<any>() }];
    const keys = Object.keys(data);
    const values = [];
    const keys2 = [];
    const osKeys = [];
    const osValues = [];

    if (keys.length) {
      keys.forEach((el, i) => {
        values[i] = data[el].quantity;
        keys2[i] = data[el]['operating systems'];
      });
      keys2.forEach((el, i) => {
        Object.keys(el).forEach((item, index) => {
          if (osKeys.includes(item)) {
            osValues[osKeys.indexOf(item)] += el[item].quantity;
          } else {
            osKeys.push(item);
            osValues.push(el[item].quantity);
          }
        });
      });
      this.chartType = this.graphService.chartType;
      this.legend = this.graphService.legend;
      this.chartOptions = this.graphService.chartOptions;

      this.chartLabels = this.graphService.getLabels(values, keys);
      this.dataSet[0].data = values;

      this.chartLabels2 = this.graphService.getLabels(osValues, osKeys);
      this.dataSet2[0].data = osValues;
    }
  }

  private displayViewerOrigin(data: { [x: string]: { [x: string]: any; }; }) {
    const keys = Object.keys(data);
    this.dataSet = [{ data: Array<any>() }];
    this.dataSet2 = [{ data: Array<any>() }];

    const values = [];
    const keys2 = [];
    const osKeys = [];
    const osValues = [];
    if (keys.length) {
      keys.forEach((el, i) => {
        values[i] = data[el].quantity;
        keys2[i] = Object.assign({}, data[el]);
        delete keys2[i].quantity;
      });

      keys2.forEach((el) => {
        Object.keys(el).forEach((item) => {
          if (osKeys.includes(item)) {
            osValues[osKeys.indexOf(item)] += el[item].quantity;
          } else {
            osKeys.push(item);
            osValues.push(el[item].quantity);
          }
        });
      });

      this.chartType = this.graphService.chartType;
      this.legend = this.graphService.legend;
      this.chartOptions = this.graphService.chartOptions;

      // this.myColors = colorLabels;
      this.chartLabels = this.graphService.getLabels(values, keys);
      this.dataSet[0].data = values;

      // this.myColors2 = colorLabels2;
      this.chartLabels2 = this.graphService.getLabels(osValues, osKeys);
      this.dataSet2[0].data = osValues;

    }

  }

  private getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const s = '0.6';
    return 'rgba(' + r + ',' + g + ',' + b + ',' + s + ')';
  }

  ngOnInit(): void {
    this.resultData = [];
    this.dtOptions = {
      paging: true,
      pagingType: 'full_numbers',
      responsive: true,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', exportOptions: { columns: ':visible' } },
        {
          extend: 'csv', exportOptions: { columns: ':visible' },
          messageTop: () => {
            return 'Date Range:  ' + this.dataService.dateRangeText + '    Total Cost  ' + this.dataService.totalsData['totalCost'] + ' $' + '    ' +
              'Region:  ' + this.dataService.queryData.region + '    Panos Cost:  ' + this.dataService.panosData['costPanosTotal'] + ' $' + '    ' +
              'GB price  ' + this.dataService.queryData.gbPrice + ' $' + '    Maps Cost:  ' + this.dataService.mapsData['costTotal'] + ' $' + '  ';
          }
        },
        {
          extend: 'excel', exportOptions: { columns: ':visible' },
          messageTop: () => {
            return 'Date Range:  ' + this.dataService.dateRangeText + '    Total Cost  ' + this.dataService.totalsData['totalCost'] + ' $' + '    ' +
              'Region:  ' + this.dataService.queryData.region + '    Panos Cost:  ' + this.dataService.panosData['costPanosTotal'] + ' $' + '    ' +
              'GB price  ' + this.dataService.queryData.gbPrice + ' $' + '    Maps Cost:  ' + this.dataService.mapsData['costTotal'] + ' $' + '  ';
          }
        },
        {
          extend: 'print', exportOptions: { columns: ':visible' },
          messageTop: () => {
            return '<table>' +
              '<tr><th>Date Range:&ensp;</th><td>' + this.dataService.dateRangeText + '</td><th>&emsp;Total Cost:&ensp;</th><td>' + this.dataService.totalsData['totalCost'] + ' $' + '</td></tr>' +
              '<tr><th>Region:&ensp;</th><td>' + this.dataService.queryData.region + '</td><th>&emsp;Panos Cost:&ensp;</th><td>' + this.dataService.panosData['costPanosTotal'] + ' $' + '</td></tr>' +
              '<tr><th>GB price:&ensp;</th><td>' + this.dataService.queryData.gbPrice + ' $' + '</td><th>&emsp;Maps Cost:&ensp;</th><td>' + this.dataService.mapsData['costTotal'] + ' $' + '</td></tr>' +
              '</table>';
          }
        },
        { extend: 'colvis', collectionLayout: 'fixed three-column' }
      ],
      destroy: true,
      columns: [
        { data: 'client' },
        { data: 'origin' },
        { data: 'venue_id' },
        { data: 'loads' },
        { data: 'hits' },
        { data: 'cost' },
        { data: 'sc_Gbytes' },
        {
          data: 'user_agent',
          render: (data: any, type: any, row: any, meta: any) => {
            return $('<button></button>', {
              text: 'Details',
              type: 'button',
              class: 'btn btn-outline-primary btn-sm getDetailsUserData',
              'data-toggle': 'modal',
              'data-target': '#user-agent',
              'data-id': meta.row,
              id: meta.row,
            }).prop('outerHTML');
          }
        },
        {
          data: 'viewer_origin',
          render: (
            data: any,
            type: any,
            row: { cost: number, hits: number, loads: number }, meta: any) => {

            return $('<button></button>', {
              text: 'Details',
              type: 'button',
              class: 'btn btn-outline-primary btn-sm getDetailsViewerOrigin',
              'data-toggle': 'modal',
              'data-target': '#viewer-origin',
              id: meta.row,
            }).prop('outerHTML');
          }
        },
      ],
    };


    $(document).on('click', '.getDetailsUserData', (e) => {
      const data = this.usrData[parseInt(e.currentTarget.id)];
      const modal = $('#user-agent');
      modal.modal('show');
      this.displayUserData(data);
      // this.graphService.displayUserData(
      //   data, this.dataSet, this.dataSet2, this.chartLabels, this.chartLabels2,
      //   this.chartOptions, this.chartType, this.legend
      // );
    });

    $(document).on('click', '.getDetailsViewerOrigin', (e) => {
      const data = this.viewerOrigin[parseInt(e.currentTarget.id)];
      const modal = $('#viewer-origin');
      modal.modal('show');
      this.displayViewerOrigin(data);

      // this.graphService.displayViewerOrigin(
      //   data, this.dataSet, this.dataSet2, this.chartLabels, this.chartLabels2,
      //   this.chartOptions, this.chartType, this.legend
      // );
    });


    this.tableData = this.dataService.mainTableUpdatedResponse
      .subscribe({
        next: (data: any) => {
          const result = Object.keys(data).map((key) => data[key]);
          this.resultData = result;
          // this.resultData = this.addClients(this.resultData);
          this.resultData = this.dataService.addClients(this.resultData, this.domainsclients);
          result.map((val, index) => {
            this.usrData[index] = val.user_agent;
            this.viewerOrigin[index] = val.viewer_origin;
          });
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
