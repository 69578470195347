import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import * as moment from 'moment';
import { DataService } from 'src/app/shared/data.service';
import { Local } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  selected: { startDate: moment.Moment, endDate: moment.Moment };

  placeholder: string;

  dateRangeText: string;

  maxDate: moment.Moment;

  // tooltips = [
  //   { date: moment(), text: 'Today is just unselectable' },
  //   { date: moment().add(2, 'days'), text: 'Yeeeees!!!' }
  // ];

  dateRange: string;

  ranges: any = {};

  customRangeButton = document.querySelector("#page-content-wrapper > div > div:nth-child(2) > div > div > app-date-range-picker > div > ngx-daterangepicker-material > div > div.ranges > ul > li:nth-child(7) > button")

  dailyRanges: any = {
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(15, 'days'), moment()],
    'Current Month': [moment().startOf('month'), moment().subtract(1, 'days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  monthlyRanges: any = {
    'Current Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Current Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  };

  dailyLocale: LocaleConfig = {
    format: 'YYYY-MM-DD',
    displayFormat: 'MMMM DD, YYYY',
    separator: ' - ',
    applyLabel: 'apply',
    customRangeLabel: 'Custom Range',
  };

  monthlyLocale: LocaleConfig = {
    format: 'YYYY-MM',
    displayFormat: 'MMMM YYYY',
    separator: ' - ',
    applyLabel: 'apply',
    customRangeLabel: 'Custom Range',
  };

  constructor(public dataService: DataService) {
    this.selected = {
      // startDate: moment().subtract(1, 'days'),
      // endDate: moment().subtract(1, 'days'),
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    };
    this.maxDate = moment().subtract(1, 'days');
    this.placeholder = this.selected.startDate.format('MMMM DD, YYYY') + ' - ' + this.selected.endDate.format('MMMM DD, YYYY');
  }

  isTooltipDate = (m: moment.Moment) => {
    if (this.dataService.getTimeFilter() === 'monthly') {
      return 'Date Range will take full months';
    }
  }

  private resizeInput() {
    let pickerInput = document.getElementsByClassName('dateRangePicker')[0];
    let dateRangeText = pickerInput['value'];
    if (dateRangeText == '') {
      pickerInput['style']['width'] = this.getPlaceholder().length - 4 + 'ch';
    }
    else {
      pickerInput['style']['width'] = dateRangeText.length + 'ch';
    }
  }

  onSetDateRange(range: { startDate: moment.MomentInput; endDate: moment.MomentInput; }) {

    if (this.dataService.queryData.timeFilter === 'monthly') {
      this.dateRange = moment(range.startDate).format('YYYY-MM') + ':' +
        moment(range.endDate).format('YYYY-MM');
      this.dataService.dateRangeText =
        moment(range.startDate).format('MMM YYYY') + ' - ' + moment(range.endDate).format('MMM YYYY');
    }
    else {
      this.dateRange = moment(range.startDate).format('YYYY-MM-DD') + ':' +
        moment(range.endDate).format('YYYY-MM-DD');
      this.dataService.dateRangeText =
        moment(range.startDate).format('MMMM DD, YYYY') + ' - ' + moment(range.endDate).format('MMMM DD, YYYY');
    }
    this.dataService.setDateRange(this.dateRange);

    this.resizeInput();

  }

  getPlaceholder() {
    return this.placeholder;
  }

  ngOnInit(): void {
    const thisRange = {
      startDate: moment().subtract(1, 'days').startOf('month'),
      endDate: moment().subtract(1, 'days').endOf('month')
    };
    this.onSetDateRange(thisRange);
    this.dataService.getDateRangeText().subscribe(rangeText => {
      this.placeholder = rangeText;
      let pickerInput = document.getElementsByClassName('dateRangePicker')[0];
      // La siguiente linea es la que setea el input de manera efectiva
      pickerInput['value'] = this.getPlaceholder();
      this.resizeInput();

    });
  }


}